<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :apiName="apiName"
      :editTitle="'消防布局'"
    >

    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/fireLayout",
      searchVal: "",
      jobList: [],
      searchQuery: { deviceName: "" },
      props: [
        {
          label: "楼层",
          prop: "floor",
          align: "center",
          width:"250px",
        },

        {
          label: "图片",
          prop: "img",
          align: "center",
          width:"200px",
          type:"image"
        },
        {
          label: "位置",
          prop: "detailedLocation",
          align: "center",
        },

        {
          label: "创建时间",
          prop: "createtime",
          align: "center",
        },

        
      ],

      formProps: [
        {
          label: "楼层",
          prop: "floor",
          type: "input",
        },

        {
          label: "图片",
          prop: "img",
          type: "upload",
        },
                {
          label: "位置",
          prop: "detailedLocation",
          type: "input",
        },
      ],
    };
  },
  created() {
    // this.getJobList();
  },
  methods: {
    async getJobList() {
      await api.dictionary.getAllList({ type: "商品分类" }).then((res) => {
        this.formProps[2].selectData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
